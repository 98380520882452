import React from 'react'
import { transitions, positions } from 'react-alert'
import { Alert, AlertTitle } from '@mui/material'

export const options = {
  // you can also just use 'bottom center'
  position: positions.BOTTOM_RIGHT,
  timeout: 5000,
  offset: '30px',
  // you can also just use 'scale'
  transition: transitions.SCALE,
  containerStyle: {
    marginLeft: -20,
    marginBottom: 20,
    zIndex: 50000,
  },
}

export const AlertTemplate = ({ style, options, message, close }) => {
  return (
    <Alert severity={options.type} style={{ marginTop: '5px' }}>
      {options.type === 'error' && <AlertTitle>We&apos;ve got an error :(</AlertTitle>}
      {options.type === 'info' && <AlertTitle>FYI!</AlertTitle>}
      {options.type === 'success' && <AlertTitle>Nice job! :)</AlertTitle>}
      {message}
    </Alert>
  )
}
