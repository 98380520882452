import { render } from 'react-dom'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider as AlertProvider } from 'react-alert'
import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import ReactGA from 'react-ga4'
import { AlertTemplate, options as alertOptions } from 'shared/components/Message'
import { ThemeProvider, ErrorBoundary } from 'app/providers'

export const renderApp = async () => {
  const module = await import('app/App')
  const App = module.default

  ReactGA.initialize('G-S4VQXEHSED')

  render(
    <BrowserRouter>
      <ErrorBoundary>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
          <DndProvider backend={HTML5Backend}>
            <ThemeProvider>
              <App />
            </ThemeProvider>
          </DndProvider>
        </AlertProvider>
      </ErrorBoundary>
    </BrowserRouter>,
    document.getElementById('root') || document.createElement('div')
  )
}
